<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-12 text-center pt-5 pb-4">
						<h2>{{ $t('message.mp_title2') }}</h2>
					</div>
				</div>
				<div class="row pb-1 pt-4">
					<div class="col-12 col-md-4 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/servico.svg" alt="" width="70">
						</div>
						<div>
							<h3>{{ $t('message.sub_title2') }}</h3>
							<p>{{ $t('message.text1') }}</p>
						</div>
					</div>
					<div class="col-12 col-md-4 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/extras.svg" alt="" width="70">
						</div>
						<div>
							<h3>{{ $t('message.sub_title3') }}</h3>
							<p>{{ $t('message.text2') }}</p>
						</div>
					</div>
					<div class="col-12 col-md-4 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/qualidade.svg" width="70" alt="">
						</div>
						<div>
							<h3>{{ $t('message.sub_title4') }}</h3>
							<p>{{ $t('message.text3') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
				<div class="row">
			<div class="col-12">
				<div class="row pb-5 pt-1">
					<div class="col-12 col-md-4 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/preco.svg" alt="" width="70">
						</div>
						<div>
							<h3>{{ $t('message.sub_title5') }}</h3>
							<p>{{ $t('message.text4') }}</p>
						</div>
					</div>
					<div class="col-12 col-md-4 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/gps.svg" alt="" width="70">
						</div>
						<div>
							<h3>{{ $t('message.sub_title6') }}</h3>
							<p>{{ $t('message.text5') }}</p>
						</div>
					</div>
					<div class="col-12 col-md-4 d-flex justify-content-center">
						<div class="pr-3">
							<img src="../assets/images/assistencia.svg" width="70" alt="">
						</div>
						<div>
							<h3>{{ $t('message.sub_title7') }}</h3>
							<p>{{ $t('message.text6') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name       : 'WhyZone',
        components : {},
        data       : function () {
            return {};
        },
        methods    : {}
    };
</script>
