var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center pt-5 pb-4"},[_c('h2',[_vm._v(_vm._s(_vm.$t('message.mp_title2')))])])]),_c('div',{staticClass:"row pb-1 pt-4"},[_c('div',{staticClass:"col-12 col-md-4 d-flex justify-content-center"},[_vm._m(0),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('message.sub_title2')))]),_c('p',[_vm._v(_vm._s(_vm.$t('message.text1')))])])]),_c('div',{staticClass:"col-12 col-md-4 d-flex justify-content-center"},[_vm._m(1),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('message.sub_title3')))]),_c('p',[_vm._v(_vm._s(_vm.$t('message.text2')))])])]),_c('div',{staticClass:"col-12 col-md-4 d-flex justify-content-center"},[_vm._m(2),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('message.sub_title4')))]),_c('p',[_vm._v(_vm._s(_vm.$t('message.text3')))])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row pb-5 pt-1"},[_c('div',{staticClass:"col-12 col-md-4 d-flex justify-content-center"},[_vm._m(3),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('message.sub_title5')))]),_c('p',[_vm._v(_vm._s(_vm.$t('message.text4')))])])]),_c('div',{staticClass:"col-12 col-md-4 d-flex justify-content-center"},[_vm._m(4),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('message.sub_title6')))]),_c('p',[_vm._v(_vm._s(_vm.$t('message.text5')))])])]),_c('div',{staticClass:"col-12 col-md-4 d-flex justify-content-center"},[_vm._m(5),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('message.sub_title7')))]),_c('p',[_vm._v(_vm._s(_vm.$t('message.text6')))])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-3"},[_c('img',{attrs:{"src":require("../assets/images/servico.svg"),"alt":"","width":"70"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-3"},[_c('img',{attrs:{"src":require("../assets/images/extras.svg"),"alt":"","width":"70"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-3"},[_c('img',{attrs:{"src":require("../assets/images/qualidade.svg"),"width":"70","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-3"},[_c('img',{attrs:{"src":require("../assets/images/preco.svg"),"alt":"","width":"70"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-3"},[_c('img',{attrs:{"src":require("../assets/images/gps.svg"),"alt":"","width":"70"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-3"},[_c('img',{attrs:{"src":require("../assets/images/assistencia.svg"),"width":"70","alt":""}})])
}]

export { render, staticRenderFns }